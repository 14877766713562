<template>
  <div class="card">
    <div class="card-header"><strong>Posted by Krystel Moore:</strong></div>
    <div class="card-body">
      <div class="author-image">
        <img
          src="/assets/images/author/krystel.jpg"
          alt="Image"
          class="rounded-circle" />
      </div>
      <p>
        <i>
          <a href="https://www.linkedin.com/in/krysteld/">Krystel</a>
           heads the sales and marketing initiatives at eFlexervices. She has a solid background in sales, lead generation, training, mentoring sales reps, call centers, offshore teams, and program management. Her 17+ years of experience include diverse technical sales and leadership roles at Stamps.com, Intermedia, EasyPost, and Skava, a subsidiary of Infosys.
        </i>
      </p>
    </div>
  </div>
  <!-- Post Single - Author End -->
</template>

<script>
  export default {
    data() {
      return {}
    },
  }
</script>
